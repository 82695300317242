import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { renderMore, populateInitials, resetRender, clearFilterItems, selectFilterItem, fetchDoctorsFilter } from "../../store/features/slice-doctors";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import DoctorsFilter from "../../components/Filters/DoctorsFilter";
import DoctorListItem from "../../components/Cards/Cta";
import Section from "../../components/Section";
import Loading from "../../components/Loading";
import { Word } from '../../components/Word';
import HorizontalScroller from '../../components/HorizontalScroller';
import FavDoctorCard from "../../components/Cards/DoctorCircular";

const SelectDoctor = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlSearchParam_centre = searchParams.get('centre');
  const doctorsIDs = useSelector((state) => state.doctors.list);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);
  const centresListBasic = useSelector((state) => state.centres.basic);
  const accountData = useSelector((state) => state.account.data);
  const currentLoaded = useSelector((state) => state.doctors.currentLoaded);
  const match = useRouteMatch();
  useEffect(() => {
    dispatch(setPageClass("page-referbycentre-selectcentre"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if(doctorsListBasic){
      if(props.location.state?.source !== 'contact' && !urlSearchParam_centre){
        dispatch(populateInitials())
        dispatch(clearFilterItems())
    }
    else{
      if(urlSearchParam_centre && centresListBasic){
        const centreFromUrl = centresListBasic[urlSearchParam_centre] ? {
          value: centresListBasic[urlSearchParam_centre].slug,
          label: centresListBasic[urlSearchParam_centre].title
        } : undefined;

        if(centreFromUrl){
          dispatch(selectFilterItem({ data: centreFromUrl, filterItem: 'doctor_centre' }));
          dispatch(fetchDoctorsFilter())
        }
      }
    }
    }
  }, [dispatch, doctorsListBasic, props.location.state, urlSearchParam_centre, centresListBasic]);

  useEffect(() => {
    //reset the rendered items when accessing page (exclude back btn (POP))
    if(history.action === 'PUSH'){
      dispatch(resetRender('rp'))
    }
  }, [dispatch, history.action]);

  const renderDoctors = () => {
    return doctorsListBasic && doctorsIDs && currentLoaded ? doctorsIDs.slice(0, currentLoaded).map(id => (
      doctorsListBasic[id] ?<DoctorListItem
          key={id}
          image={doctorsListBasic[id].profileImage}
          title={parse(doctorsListBasic[id].salutation+" "+doctorsListBasic[id].name)}
          subtitle={doctorsListBasic[id].tags && doctorsListBasic[id].tags.length > 0 ? doctorsListBasic[id].tags.map((tag, index) => index > 0 ? ', '+tag.name : tag.name)  : null}
          isCentered
          icon="chevron_right"
          link={`${match.url}/${id}`}
          isFavourite = {accountData && accountData.favourites.includes(doctorsListBasic[id].slug) ? true : false}
      /> : null
    )) : null;
  }

  const renderFavDoctorsCards = () => {
    return accountData && accountData.favourites && doctorsListBasic ? accountData.favourites.map(id => (
      doctorsListBasic[id] ? 
        <FavDoctorCard
            key={id}
            slug={doctorsListBasic[id].slug}
            image={doctorsListBasic[id].profileImage}
            title={parse(doctorsListBasic[id].salutation+" "+doctorsListBasic[id].name)}
            link={`/refer-patient/${doctorsListBasic[id].slug}`}
        /> : null
    )) : null;
  };
  
  return (
    <>
      <PageHead 
        className="page-referbycentre-selectcentre--page-head"
        title="Refer a Patient"
        subtitle="Search by an Icon doctor's name or use the filter options below to refine your search."
        bg="blue"
      />
      <PageContent className="page-referbycentre-selectcentre--page-content">
        <Section className="split-content w-40-60">
          <DoctorsFilter 
            className="page-referbycentre-selectcentre--filter" 
            filter="rp"
          />
          <Section className="page-referbycentre-selectcentre--list attached-list">
            {accountData && accountData.favourites.length > 0 ? 
              <div className="page-referbycentre-selectcentre--favourites">
                <h2><Word value="Your favourites" /></h2>
                <HorizontalScroller scrollOffset={180}>
                  {renderFavDoctorsCards()}
                </HorizontalScroller>
              </div>
            : null}
            {doctorsIDs && doctorsIDs.length > 0 ? 
              <InfiniteScroll
                dataLength={currentLoaded}
                next={() => dispatch(renderMore('rp'))}
                hasMore={(currentLoaded < doctorsIDs.length)}
                loader={<Loading />}
                scrollThreshold={0.7}
              >
                {renderDoctors()}
              </InfiniteScroll>
            : null }
          </Section>
        </Section>
      </PageContent>
    </>
  );
};

export default SelectDoctor;
