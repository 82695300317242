import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import Button from "./Button";
import { ReactComponent as FilterIcon } from '../assets/images/icons/filter.svg';
import { ReactComponent as CloseIcon } from '../assets/images/icons/close.svg';
import { ReactComponent as BackIcon } from '../assets/images/icons/chevron-left.svg';
import { ReactComponent as CheckedIcon } from '../assets/images/icons/check.svg';
import { Word } from "../components/Word";
import G from "../app/globals";




const PageHead = (props) => {
    const history = useHistory();
    const headerConfig = useSelector((state) => state.ui.header);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
    let icon = null;
    switch(props.icon){
        case 'filter': icon = <FilterIcon />
        break;
        case 'close' : icon = <CloseIcon />
        break;
        default: icon = null
    }
    let secondaryIcon = null;
    switch(props.secondaryIcon){
        case 'check': secondaryIcon = <CheckedIcon />
        break;
        default: secondaryIcon = null
    }
    return (
        <div className={`page-head${props.className ? ' '+props.className : ''}${props.bg === 'blue' && !isDesktop ? ' blue' : ''}`}>
            <div className="page-head--inner">
                {isDesktop && headerConfig.back ?  
                    <Button 
                        className={`btn-plain page-head--back-btn`}
                        text={<Word value="Back" />}
                        textIcon={<BackIcon />}
                        Clicked={headerConfig.backTo ? () => history.push(headerConfig.backTo) : () => history.goBack()}
                    />
                : null}
                {props.breadcrumb ? <div className="page-head--breadcrumb">{props.breadcrumb} </div> : null}
                {props.title ? 
                <div className={`page-head--title`}>
                     <h1><Word value={props.title} /></h1>
                     {props.icon ? 
                        <Button 
                            className={`btn-plain${props.bg === 'blue' && !isDesktop ? ' text-yellow' : ''}${props.icon ? ' '+props.icon : ''}`}
                            text={props.iconText}
                            icon={icon}
                            Clicked={props.Clicked}
                            textIcon={secondaryIcon}
                        />
                        : null
                     }
                </div>
                : null}
                {props.subtitle ? <p className="page-head--subtitle"><Word value={props.subtitle} /></p> : null}
            </div>
        </div>
    );
};

export default React.memo(PageHead);